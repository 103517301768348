<template>
  <main class="dark:bg-black overflow-hidden">
    <teleport to="head">
      <meta name="title" content="Contact - Hasolu" />
      <meta
        name="description"
        content="
Contact information to contact us"
      />
      <meta name="robots" content="index, follow" />

      <meta name="language" content="English" />
      <meta name="author" content="hasolu" />
    </teleport>
    <Header title="Contact" @ContactShow="show = false" />
    <div
      class="container h-[calc(100vh-9rem)] sm:h-[calc(100vh-12rem)] mt-12  mx-auto flex flex-col sm:flex-row "
    >
      <div
        class=" w-full h-1/2 sm:h-full  flex flex-col items-center justify-start pt-8 sm:pt-44 "
      >
        <div class=" w-auto h-auto">
          <transition
            enter-active-class="animate__animated animate__fadeIn delay-1  "
            leave-active-class="animate__animated animate__slideOutDown "
            mode="out-in"
          >
            <div v-if="show" class="relative">
              <transition
                enter-active-class="animate__animated animate__fadeInDown animate__faster   "
                leave-active-class="animate__animated animate__fadeOutUp animate__faster "
                mode="out-in"
              >
                <span
                  class="absolute left-0 bg-red-500 font-hsl text-white  rounded-full px-2 text-xs sm:text-sm -top-5  "
                  v-if="tooltip"
                >
                  Copied to clipboard !
                </span>
              </transition>
              <h1
                @click="mailMethod"
                ref="mailadress"
                class="text-[2.5rem] select-none sm:text-[5rem] text-black dark:text-white cursor-pointer font-hslBold "
              >
                hi@hasolu.codes
              </h1>
            </div>
          </transition>
          <div
            class="w-full h-auto justify-center sm:justify-start flex space-x-4 sm:space-x-8 mt-1 select-none text-gray-400 dark:text-hslwhite"
          >
            <transition
              enter-active-class="animate__animated animate__fadeIn delay-2   "
              leave-active-class="animate__animated animate__fadeOut animate__faster "
              mode="out-in"
            >
              <a
                v-if="show"
                href="https://instagram.com/halilhsl"
                target="_blank"
              >
                <span
                  class="flex items-center cursor-pointer group hover:text-red-500 transition-all "
                >
                  <LogoInstagramIcon
                    class="text-xl fill-current dark:fill-current dark:text-white transition-all text-gray-400 group-hover:fill-current group-hover:text-red-500  sm:text-4xl"
                  />

                  <span class="font-hslMedium ml-1 text-sm sm:text-lg"
                    >Instagram</span
                  >
                </span>
              </a>
            </transition>
            <transition
              enter-active-class="animate__animated animate__fadeIn delay-3  "
              leave-active-class="animate__animated animate__fadeOut animate__faster "
              mode="out-in"
            >
              <a v-if="show" href="https://github.com/hasolu" target="_blank">
                <span
                  class="flex items-center cursor-pointer group hover:text-red-500 transition-all "
                >
                  <LogoGithubIcon
                    class="text-xl fill-current dark:fill-current dark:text-white transition-all text-gray-400 group-hover:fill-current group-hover:text-red-500  sm:text-4xl"
                  />
                  <span class="font-hslMedium ml-1 text-sm sm:text-lg"
                    >GitHub</span
                  >
                </span>
              </a>
            </transition>
            <transition
              enter-active-class="animate__animated animate__fadeIn delay-4   "
              leave-active-class="animate__animated animate__fadeOut animate__faster "
              mode="out-in"
            >
              <a
                v-if="show"
                href="https://www.linkedin.com/in/hasolu/"
                target="_blank"
              >
                <span
                  class="flex items-center cursor-pointer group hover:text-red-500 transition-all "
                >
                  <LogoLinkedinIcon
                    class="text-xl fill-current dark:fill-current dark:text-white transition-all text-gray-400 group-hover:fill-current group-hover:text-red-500  sm:text-4xl"
                  />
                  <span class="font-hslMedium ml-1 text-sm sm:text-lg"
                    >LinkedIn</span
                  >
                </span>
              </a>
            </transition>
          </div>
        </div>
      </div>
      <div class="w-full h-full relative">
        <transition
          enter-active-class="animate__animated animate__slideInUp delay-1 "
          leave-active-class="animate__animated animate__slideOutDown "
          mode="out-in"
        >
          <span
            v-if="show"
            class=" w-full h-full z-10 absolute david-contact"
          ></span>
        </transition>
        <transition
          enter-active-class="animate__animated animate__zoomIn delay-25 "
          leave-active-class="animate__animated animate__zoomOut "
          mode="out-in"
        >
          <span
            v-if="show"
            class=" w-full h-full absolute dark:david-bgwhite-contact david-bg-contact"
          ></span>
        </transition>
      </div>
    </div>
  </main>
</template>
<script>
import Header from "@/components/Header";
import LogoInstagramIcon from "vue-ionicons/dist/logo-instagram.vue";
import LogoGithubIcon from "vue-ionicons/dist/logo-github.vue";
import LogoLinkedinIcon from "vue-ionicons/dist/logo-linkedin.vue";
export default {
  data() {
    return {
      show: false,
      tooltip: false,
    };
  },
  components: { Header, LogoInstagramIcon, LogoGithubIcon, LogoLinkedinIcon },
  mounted() {
    this.show = true;
  },
  methods: {
    mailMethod() {
      navigator.clipboard.writeText(this.$refs.mailadress.innerHTML);
      this.tooltip = true;
      setTimeout(() => {
        this.tooltip = false;
      }, 1250);
    },
  },
};
</script>
